import * as React from "react"
// import https from "https"
import fetch from "node-fetch"
import { Helmet } from "react-helmet"
import { motion } from "framer-motion"
import { rejectedAnswers } from "../../firebase_functions/rejectedAnswers"
import Logo from "../components/Logo"

import { enterCheck } from "../components/Game/Constants"
import SearchBox from "../components/Game/SearchBox"
import AnswerGrid from "../components/Game/AnswerGrid"

// const httpsAgent = new https.Agent({ keepAlive: true })

const TesterPage = () => {
	const [testQuery, setTestQuery] = React.useState<string>("")
	const [testAnswers, setTestAnswers] = React.useState<string[]>([])
	const [testedQuery, setTestedQuery] = React.useState<string>("")
	const [usedQuestions, setUsedQuestions] = React.useState<string[]>([])

	const refreshResults = async (
		event?: React.KeyboardEvent<HTMLInputElement>,
		useThisQuery?: string
	) => {
		const question = (useThisQuery || testQuery).trim()
		if ((event && enterCheck(event)) || question.length === 0) {
			return document?.getElementById("mainInput")?.focus()
		}
		if (!useThisQuery) {
			const tempUsedQuestions: string[] = usedQuestions
			tempUsedQuestions.push(question)
			setUsedQuestions(tempUsedQuestions)
		}
		const response = await fetch(
			"https://google.com/complete/search?client=youtube&q=" +
				encodeURI(question) +
				"%20"
		)
		if (response.status !== 200) {
			console.log(response.status)
		} else {
			const responseText = await response.text()
			const responseArray = responseText.match(/\[{2}.*\]{3}/)
			const answers =
				responseArray === null
					? {}
					: JSON.parse(responseArray[0])
							.map(
								(result: string) =>
									typeof result[0] === "string" &&
									result[0].substring(0, question.length + 1) ===
										question + " " &&
									result[0].trim() !== question &&
									result[0].indexOf("google feud") === -1 &&
									result[0].length - question.length < 60 &&
									rejectedAnswers.indexOf(
										result[0].substring(question.length + 1).trim()
									) === -1 &&
									result[0].substring(question.length + 1).trim()
							)
							.filter((obj: string) => obj)
							.slice(0, 13)

			setTestedQuery(responseArray === null ? "no " : testQuery)
			setTestAnswers(
				responseArray === null ? new Array(13).fill("results") : answers
			)
		}
	}

	return (
		<>
			<Helmet>
				<title>Google Feud - Query Tester</title>
			</Helmet>
			<Logo />
			<h2>Secret Query Tester</h2>
			<motion.div
				id="SearchBox"
				key="sb"
				style={{
					border: "1px solid rgba(0,0,0,0)",
					borderRadius: "var(--single)",
					display: "inline-block",
					minWidth: "600px",
					padding: "0",
				}}
				layout
				animate={{
					transition: { duration: 0.5 },
					borderColor: "var(--gray)",
				}}
				transition={{
					type: "spring",
					bounce: 0.5,
					duration: 1,
				}}>
				<SearchBox
					clear={() => setTestQuery("")}
					numberOfGuessesOrStatic={Infinity}
					query={""}
					giveUp={false}
					response={testQuery}
					setResponse={setTestQuery}
					submitAnswer={refreshResults}
					wrong={0}
					tester
				/>
				<hr />
				<AnswerGrid
					query={testedQuery}
					answers={testAnswers}
					correctAnswers={[]}
					roundOver={true}
					giveUp={false}
					tester
				/>
			</motion.div>
			<ul>
				{usedQuestions.map((question) => (
					<li key={`li${question}`}>
						<button
							onClick={() => {
								setTestQuery(question)
								refreshResults(undefined, question)
							}}>
							{question}
						</button>
					</li>
				))}
			</ul>
		</>
	)
}

export default TesterPage
